import * as React from "react";
import { useState, useEffect } from "react";
import { List, Datagrid, NumberField, TextField, DateField, TopToolbar, CreateButton } from "react-admin";
import { useDataProvider, useNotify } from "react-admin";
import Actions from "./Actions";
import { Route } from "react-router";
import Create from "./Create";
import { Drawer, Tooltip, Typography } from '@material-ui/core';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";

const SearchList = (props: any) => (
    <>
        <List
            {...props}
            actions={<TopActions />}
            bulkActionButtons={false}
            sort={{ field: "date", order: "DESC" }}
        >
                <Datagrid rowClick={undefined}>
                    <DateField source="date" label="Created on" />
                    <TextField source="postcode" />
                    <TextField source="distance" />
                    <DateField source="startDate" />
                    <DateField source="endDate" />
                    <AutoExtendField label={<AutoExtendFieldLabel />} />
                    <NumberField source="resultCount" label="Results" />
                    <NumberField source="fetchCount" label="Fetched" />
                    <NumberField source="duplicateCount" label="Duplicates" />
                    <TextField source="progress" />
                    <Actions />
                </Datagrid>
        </List>
        <Route path="/search/create">
            {({ match }) => (
                <Drawer open={!!match} onClose={() => props.push(`/search`)} anchor="right">
                    <Create {...props} title=" " onCancel={() => props.push(`/search`)} />
                </Drawer>
            )}
        </Route>
    </>
);

const TopActions = ({ basePath }: any) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label="New search" />
    </TopToolbar>
);

const AutoExtendFieldLabel = () => (
    <Tooltip title="Automatically extend the search end date every night" arrow>
        <Typography variant="body2">
            Auto Extend?
        </Typography>
    </Tooltip>
)

const AutoExtendField = ({ record }: any) => {
    const [checked, setChecked] = useState(record.autoExtend);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const extend = (autoExtend: boolean) => dataProvider
        .update(`search`, { id: record.id, data: { autoExtend }, previousData: { id: record.id } })
        .then(() => notify(autoExtend ? `Search will auto-extend overnight!` : `Search will no longer auto-extend overnight`))
        .catch(() => notify(`Could not extend`));

    useEffect(() => setChecked(record?.autoExtend), [record])

    // only allow this if the end date is within 25 days
    const extendDisabled = moment(record?.endDate).startOf("day").isBefore(moment().subtract(25, "days"));

    const toggle = () => {
        extend(!checked);
        setChecked(!checked);
    }
    
    return (
        <Checkbox checked={checked} onClick={toggle} disabled={extendDisabled} />
    )
}
    

export default connect(
    undefined,
    { push },
)(SearchList);