import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    DateField,
    BooleanField,
} from "react-admin";
import Actions from "../lead/Actions"; // TODO: move to common
import { SearchFilter } from "../lead/List"; // TODO: move to common
import TopActions from "./TopActions";
import ExpandedResult from "../../common/ExpandedResult";

export default (props: any) => (
    <List
        {...props}
        sort={{ field: "fetchedAt", order: "DESC" }}
        filterDefaultValues={{ postage: "$ne" }}
        filters={<SearchFilter />}
        actions={<TopActions />}
        exporter={false}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="expand" expand={<ExpandedResult />}>
            <TextField source="id" label="ID" />
            <TextField source="propertyAddress" />
            <EmailField source="email" emptyText="none" />
            <TextField source="telephone" emptyText="none" />
            <DateField source="published" label="Added" />
            <BooleanField
                source="letterSent"
                label="Letter sent?"
                valueLabelTrue="Letter sent"
                valueLabelFalse="Letter not sent"
            />
            <Actions label="Actions" allowResends={true} />
        </Datagrid>
    </List>
);