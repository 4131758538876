import * as React from "react";
import {
    required,
    TextInput,
    Create as RACreate,
    Edit as RAEdit,
    TabbedForm,
    FormTab,
} from "react-admin";

export const Create = (props: any) => (
    <RACreate {...props}>
        <Form {...props} />
    </RACreate>
)

export const Edit = (props: any) => (
    <RAEdit {...props} undoable={false}>
        {Form(props)}
    </RAEdit>
)

const Form = (props: any) => {
    return (
        <TabbedForm {...props} redirect="edit">
            <FormTab label="info">
                <TextInput
                    source="contactAddress.address1"
                    label="Address line 1"
                    validate={[required()]}
                />
                <TextInput
                    source="contactAddress.address2"
                    label="Address line 2"
                />
                <TextInput
                    source="contactAddress.city"
                    label="City"
                    validate={[required()]}
                />
                <TextInput
                    source="contactAddress.postcode"
                    label="Postcode"
                    validate={[required()]}
                />
                <TextInput
                    source="contactAddress.country"
                    label="Country"
                    defaultValue={"GB"}
                    disabled
                />
                <TextInput
                    source="comment"
                    multiline={true}
                    label="Comments"
                />
                <TextInput
                    source="email"
                    label="Email"
                />
                <TextInput
                    source="telephone"
                    label="Telephone"
                />
            </FormTab>

        </TabbedForm>
    );
}
