import edit from "./Edit";
import { TrackChanges as icon }  from "@material-ui/icons";

export default {
    name: "tracker",
    options: {
        label: "Tracker",
        defaultPath: "tracker/default",
    },
    edit,
    icon,
}