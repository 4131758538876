import list from "./List";
import { Search as icon }  from "@material-ui/icons";

export default {
    name: "search",
    list,
    icon,
    options: {
        label: "Searches",
    }
}