import getConfig from "./config";
import decodeJwt from 'jwt-decode';

export default {
    login: async ({ username, password }: any) => {
        const config = getConfig();
        const request = new Request(`${config.apiUrl}/user/login`, {
            method: "POST",
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        const response = await fetch(request);

        if (response.status === 401) {
            throw new Error(`Invalid credentials`);
        }

        if (response.status === 402) {
            throw new Error(`Your subscription has expired`);
        }

        if (response.status !== 200) {
            throw new Error(`Please login`);
        }
        const { token } = await response.json();
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("profile", JSON.stringify(decodeJwt(token)));
    },
    logout: async () => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("token");
    },
    checkAuth: async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error(`Please log in`);
        }
        // TODO: check sesion length on the client from exp
        // const decoded = decodeJwt(token);
        // return true;
    },
    checkError: async (error: any) => {
        console.log(error.status, typeof error.status);
        if (error.status !== 401) {
            return;
        }
        throw new Error(`Your session has ended`);
    },
    getPermissions: async () => {
        return [];
    },
};

export const getToken = () => sessionStorage.getItem("token");
