import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Map from "./GoogleMap";
import styled from "styled-components";
import ListCore from '@material-ui/core/List';
import _ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HouseIcon from '@material-ui/icons/House';
import WorkIcon from '@material-ui/icons/Work';
import Tooltip from '@material-ui/core/Tooltip'
import SendIcon from '@material-ui/icons/Send'
import TextField from '@material-ui/core/TextField';
import useDebounce from "../hooks/debounce";
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import ResultLinks from "./ResultLinks";

export default ({ id, record, resource, showActions = false }: any) => {
    const [comment, setComment] = useState(record?.comment);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!record) {
        return null;
    }

    const commentDebounced = useDebounce(comment, 1000);

    useEffect(() => {
        const changed = commentDebounced !== record.comment;
        const save = () => dataProvider
            .update("search_result", { // TODO: change
                id: record._id,
                data: { comment: commentDebounced },
                previousData: {
                    id: record._id,
                },
            })
            .catch(() => notify(`Could not save comment`));
        if (commentDebounced && changed) {
            save();
        }
    }, [commentDebounced, dataProvider, record._id, record.comment, refresh, resource, notify])


    const searchExecutor = useCallback(() => {
        window.open(`https://www.google.com/search?q=${record.contact} ${record.contactAddress}`);
    }, [record.contact, record.contactAddress]);

    const searchProperty = useCallback(() => {
        window.open(`https://www.google.com/search?q=${record.propertyAddress}`);
    }, [record.propertyAddress]);

    const openLetter = useCallback(() => {
        window.open(record.postage.pdfUrl);
    }, [record.postage]);

    return (
        <Wrapper>
            <MapWrapper>
                <Map lat={record?.propertyLocation?.lat} lng={record?.propertyLocation?.long} />
            </MapWrapper>
            <Info>
            <ListCore>
                <ListItem alignItems="flex-start" onClick={searchExecutor}>
                    <ListItemAvatar>
                        <Tooltip title="Executor details">
                            <Avatar>
                                <WorkIcon />
                            </Avatar>
                        </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${record.contact}`}
                        secondary={
                            <>
                                {/* {record.contactAddress} */}
                                <br/>
                                <span onClick={e => e.stopPropagation()}>
                                    Reference: {record.reference}
                                </span>
                            </>
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start" onClick={searchProperty}>
                    <ListItemAvatar>
                        <Tooltip title="Deceased">
                            <Avatar>
                                <HouseIcon />
                            </Avatar>
                        </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                        primary={record.propertyAddress}
                        secondary={
                            <>
                                {record.nameOfDeceased}
                                <br/>
                                {`deceased ${moment(record.dateOfDeath).format(`DD/MM/YYYY`)}`}
                            </>
                        }
                    />
                </ListItem>
                {record.postage && (
                    <ListItem alignItems="flex-start" onClick={openLetter}>
                        <ListItemAvatar>
                            <Avatar>
                                <SendIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Letter"
                            secondary={
                                record.postage ?
                                    `sent 2nd class at ${moment(record.postage.sentAt).format(`DD/MM/YYYY HH:mma`)} ${record.postage.test ? `(TEST MODE)` : ``}` :
                                    `not sent yet`
                            }
                        />
                    </ListItem>
                )}

                <ListItem>
                    <br/>
                    <TextField
                        fullWidth
                        id="filled-textarea"
                        label="Comments"
                        placeholder="Comments"
                        multiline
                        variant="outlined"
                        size="small"
                        value={comment}
                        onChange={e => setComment(e.currentTarget.value)}
                    />
                </ListItem>


                {showActions && (
                    <ResultLinksWrapper>
                        <ResultLinks record={record} />
                    </ResultLinksWrapper>
                )}

                </ListCore>

            </Info>
        </Wrapper>
    )
}

const ResultLinksWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const MapWrapper = styled.div`
    flex: 6;
`;

const Info = styled.div`
    flex: 4;
`;

const ListItem: any = styled(_ListItem)`
    &:hover {
        cursor: pointer;
    }
`;