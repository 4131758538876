import * as React from "react";
import { useDataProvider, useNotify } from "react-admin";
import { IconButton, Tooltip } from "@material-ui/core";
import { Check, Clear, Undo } from "@material-ui/icons";
import styled from "styled-components";
import ResultLinks from "../../common/ResultLinks";
import { connect } from "react-redux";
import { deleteSearchResultLocal } from "../../redux/actions";

const Actions: React.FC<any> = ({ record, deleteSearchResultLocal }) => {

    const notify = useNotify();
    const dataProvider = useDataProvider();

    if (!record) {
        return null;
    }

    const update = (status: "lead" | "ignored" | "fetched") => async (e: any) => {
        e.stopPropagation();
        try {
            await dataProvider.update("search_result", {
                id: record._id,
                data: { status },
                previousData: {
                    id: record._id,
                }
            });
            if (status === "ignored") {
                notify(`Ignored, use the status filter to view`);    
            }
            if (status === "lead") {
                notify(`Saved as a lead, check the leads tab`);
            }
            if (status === "fetched") {
                notify(`Un-ignored, use the status filter to view`);
            }
            deleteSearchResultLocal(record.id);
        } catch (e) {
            console.error(e);
        }
    }

    const { status } = record;

    return (
        <Row>
            <ResultLinks record={record} />
            {status === "ignored" ? (
                <Tooltip title="Undo ignore">
                    <IconButton onClick={update("fetched")}>
                        <Undo />
                    </IconButton>
                </Tooltip>
            ) : (
                <>
                    <Tooltip title="Convert to lead">
                        <IconButton onClick={update("lead")}>
                            <Check />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ignore">
                        <IconButton onClick={update("ignored")}>
                            <Clear />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Row>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export default connect(undefined, {
    deleteSearchResultLocal,
})(Actions);