import React, { useEffect, useState, useCallback } from "react";
import _Board from 'react-trello'
import { useDataProvider } from "react-admin";
import Modal from "../../common/Modal";
import ExpandedResult from "../../common/ExpandedResult";
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";


export default (props: any) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any>();
    const [modalVisible, setModalVisible] = useState<string>();
    useEffect(() => {
        dataProvider.getOne(`tracker`, { id: "default" })
            .then(({ data }) => setData(data))
            .catch(e => console.error(e))
    }, [dataProvider, setData]);

    const onCardMove = useCallback(async (fromLaneId: string, toLaneId: string, cardId: string, index: number) => {
        const data = { fromLaneId, toLaneId, cardId, index };
        await dataProvider.update(`tracker/default`, { id: "card", data, previousData: { id: "card" } })
    }, [dataProvider]);

    const onCardClick = useCallback((cardId: string) => {
        setModalVisible(cardId);
    }, [setModalVisible]);

    if (!data) {
        return <CircularProgress />
    }

    return (
        <>
        <Board
            data={data}
            style={{backgroundColor: 'transparent', width: '98%', paddingBottom: 300, marginBottom: 100 }}
            onCardMoveAcrossLanes={onCardMove}
            hideCardDeleteIcon={true}
            onCardClick={onCardClick}
        />
        <Modal open={!!modalVisible} onClose={() => setModalVisible(undefined)}>
            <Preview id={modalVisible} />
        </Modal>
        </>
    )
}

const Preview = ({ id }: any) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any>();

    useEffect(() => {
        dataProvider.getOne(`lead`, { id })
            .then(({ data }) => setData(data))
            .catch(e => console.error(e))
    }, [dataProvider, setData, id]);

    if (!data) {
        return <CircularProgress />
    }

    return (
        <ExpandedResult record={data} resource="lead" showActions={true} />
    );
};

const Board = styled(_Board)`
    article > header > span:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;