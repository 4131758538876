import * as React from "react";
import {
    required,
    SimpleForm,
    DateInput,
    minValue,
    maxValue,
    NumberInput,
    TextInput,
    Create,
    useNotify,
    useRedirect,
    useRefresh,
    Toolbar,
    SaveButton,
} from "react-admin";
import Button from '@material-ui/core/Button';
import moment from "moment";

export default (props: any) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('Search started, refresh to check progress!')
        redirect('/search');
        refresh();
        setTimeout(() => refresh(), 5000);
    };
    return (
        <Create {...props} onSuccess={onSuccess} >
            <SimpleForm toolbar={<CreateToolbar onCancel={props.onCancel} />}>
                <TextInput
                    source="postcode"
                    label="Post Code"
                    validate={[required()]}
                />
                <NumberInput
                    source="distance"
                    label="Distance (in miles)"
                    validate={[required(), minValue(1), maxValue(10)]}
                />
                <DateInput
                    source="startDate"
                    label="Start publication date"
                    validate={[required()]}
                    defaultValue={moment().subtract(2, "weeks").toDate()}
                    />
                <DateInput
                    source="endDate"
                    label="End publication date"
                    validate={[required()]}
                    defaultValue={moment().toDate()}
                />
            </SimpleForm>
        </Create>
    );
}


const CreateToolbar = ({ onCancel, translate, ...props }: any) => (
    <Toolbar {...props}>
        <SaveButton label="Search" />
        <Button onClick={onCancel}>cancel</Button>
    </Toolbar>
);