import * as React from 'react';
import { Layout, Sidebar, Notification, AppBar } from 'react-admin';
// import MyAppBar from './AppBar';
import Menu from "./Menu";
// import MySidebar from './MySidebar';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';

export default (props: any) => (
    <Layout
        {...props}
        appBar={AppBar}
        sidebar={Sidebar}
        menu={Menu}
        notification={Notification}
    />
)
