import * as React from "react";
import GoogleMapReact from 'google-map-react';
import { PinDrop } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import Actions from "./Actions";

interface Record {
    propertyLocation: {
        lat: number;
        long: number;
    };
    propertyAddress: string;
    claimDeadline: string;
    contact: string;
    contactAddress: string;
    hasEmail: boolean;
    hasTelephone: boolean;
    lat?: number;
    lng?: number;
    id: string;
    text?: string;
}

interface Props {
    records: Record[];
}

const Wrapper = styled.div<{ active?: boolean }>`
    width: 30px;
    height: 30px;
    font-size: 8px;
    background-color: ${({ active }) => active ? `red` : `rgba(255, 255, 255, 0.9)`};
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 15px;
    border: 1px solid black;
`;

const Pin = (props: Record) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <div style={{position: "relative"}}>
            <Wrapper onClick={() => setOpen(!open)} active={open}>
                <PinDrop />
            </Wrapper>
            {open && (
                <Card style={{position: 'absolute', top: 35, left: -130, width: 300, zIndex: 9999}}>
                    <CardContent>
                        <Typography variant="body2" style={{fontWeight: "bold"}}>
                            {props.propertyAddress}
                        </Typography>
                        <hr />
                        <Typography variant="caption">
                            <b>ID:</b> {props.id}
                            <br />
                            <b>Executor:</b> {props.contact}, {props.contactAddress}
                            <br />
                            <b>Claim deadline:</b> {moment(props.claimDeadline).format(`DD/MM/YYYY`)}
                            <br />
                            <b>Has telephone:</b> {props.hasTelephone ? `Yes` : `No`}
                            <br />
                            <b>Has email:</b> {props.hasTelephone ? `Yes` : `No`}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Actions record={props} />
                    </CardActions>
                </Card>
            )}
        </div>
    )
}

const Map = ({ records }: Props) => {
    return (
        <div style={{ height: '700px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyC3ODt95y5xAJK1_NLrxv72ZECu9b8-cqg" }}
                defaultCenter={{
                    lng: -1.158109,
                    lat: 52.954784,
                }}
                defaultZoom={7}
            >
            {records.map((record, i) => (
                <Pin key={i} {...record} lng={record.propertyLocation.long} lat={record.propertyLocation.lat} />
            ))}

            </GoogleMapReact>
        </div>
    )
}

export default React.memo(Map);
