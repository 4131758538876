import React, { FC, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import _AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import styled from "styled-components";

type PdfType = "contact" | "property";

interface Props {
  pdf: string;
  pdfLoading?: boolean;
  onPdfChange?: (type: PdfType) => void;
  title: string;
  onClose: (e: any) => void;
  sendPdf: (e: any) => Promise<boolean>;
}

const PdfPreview: FC<Props> = ({ pdf, title, onClose, sendPdf, onPdfChange, pdfLoading }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => setOpen(true), []);

  const send = async (e: any) => {
    if (await sendPdf(e)) {
      onClose(e);
    }
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Title variant="h6">
            {title}
          </Title>
          {onPdfChange ? (
            <SelectWrapper>
              <select onChange={e => onPdfChange(e.target.value as PdfType)}>
                <option value={"contact"}>To: Contact / Executor</option>
                <option value={"property"}>To: Property address</option>
              </select>
            </SelectWrapper>
          ) : null}
          <IconButton edge="start" color="inherit" onClick={send} aria-label="close">
            <SendIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {pdf && !pdfLoading && <Pdf data={`data:application/pdf;base64,${pdf}`} type="application/pdf" />}
    </Dialog>
  );
}

export default PdfPreview;

const SelectWrapper = styled.div`
  margin-right: 5rem;
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Pdf = styled.object`
    width: 100%;
    height: 100%;
`;

const AppBar = styled(_AppBar)`
    position: relative;
`;

const Title = styled(Typography)`
    flex: 1;
    margin-left: 1.5rem;
`;