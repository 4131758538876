import * as React from "react";
import { Link } from "react-router-dom";
import { stringify } from "query-string";
import { FormatListNumbered, FileCopy, Map, CalendarToday } from "@material-ui/icons"
import { Tooltip, IconButton } from "@material-ui/core";
import moment from "moment";
import { useDataProvider, useNotify, useRefresh } from "react-admin";

export default ({ record }: any) => {
    const extendDisabled = !(moment(record?.endDate).startOf("day").isBefore(moment().startOf("day")));
    return record ? (
        <>
            <Tooltip title="View results as a list">
                <IconButton
                    component={Link}
                    to={{
                        pathname: '/search_result',
                        search: stringify({
                            ...defaultSearch,
                            filter: JSON.stringify({
                                searchId: record.id,
                                status: "fetched",
                            }),
                        }),
                    }}
                >
                    <FormatListNumbered />
                </IconButton>
            </Tooltip>
            <Tooltip title="View results on a map">
                <IconButton
                    component={Link}
                    to={{
                        pathname: '/search_result',
                        search: stringify({
                            ...defaultSearch,
                            filter: JSON.stringify({
                                searchId: record.id,
                                mapView: true,
                                status: "fetched",
                            }),
                        }),
                    }}
                >
                    <Map />
                </IconButton>
            </Tooltip>
            <CopyButton
                record={record}
                disabled={extendDisabled}
            />
            <ExtendButton
                record={record}
                disabled={extendDisabled}
            />
        </>
    ) : null;
};

const defaultSearch = {
    page: 1,
    perPage: 25,
    sort: 'fetchedAt',
    order: 'DESC',
}

const CopyButton = ({ record, disabled }: any) => (
    <Tooltip title="Copy search">
        <IconButton
            disabled={disabled}
            component={Link}
            to={{
                pathname: '/search/create',
                state: {
                    record: {
                        postcode: record.postcode,
                        // distance: parseInt(record.distance), // not working
                        startDate: moment(record.endDate).add(1, "days").toDate(),
                        endDate: moment().toDate(),
                    },
                },
            }}
        >
            <FileCopy />
        </IconButton>
    </Tooltip>
);

const ExtendButton = ({ record, disabled }: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const extend = () => dataProvider
        .create(`search/${record.id}/extend`, { data: {} })
        .then(() => [notify(`Extending search to today...`), refresh()])
        .catch(() => notify(`Could not extend`));

    return (
        <Tooltip title="Extend search to today">
        <IconButton
            disabled={disabled}
            onClick={extend}
        >
            <CalendarToday />
        </IconButton>
    </Tooltip>
    )

}