import list from "./List";
import { FormatListNumbered as icon }  from "@material-ui/icons";

export default {
    name: "search_result",
    options: {
        label: "Results",
    },
    list,
    icon,
}