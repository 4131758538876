import React from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin';
import resources from "./resources";
import authProvider from "./services/auth";
import dataProvider from "./services/data";
import Layout from "./layout";

const App = () => (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      appLayout={Layout}
    >
        {resources.map(item => <Resource key={item.name} {...item} />)}
    </Admin>
);

export default App;
