export default (): Config => {
    switch (process.env.NODE_ENV) {
        case "production":
            return prod;
        case "development":
        default:
            return local;
    }
}

const local: Config = {
    apiUrl: `http://localhost:5000/dev`,
}

const prod: Config = {
    apiUrl: `https://api.myprobate.properties`,
}

type Config = {
    apiUrl: string;
}