import * as React from "react";
import { Typography } from "@material-ui/core";
import { Edit, SimpleForm, TextInput as _TextInput, Toolbar, SaveButton, BooleanInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import styled from "styled-components";
import moment from "moment";

export default (props: any) => {
    return (
        <Edit {...props} undoable={false} aside={<EditAside />}>
            <SimpleForm redirect="edit" toolbar={<EditToolbar />}>
                <TextInput source="email" disabled />
                <TextInput source="letterBody" label="Letter Body (Probates)" multiline />
                <TextInput source="letterBodyCustom" label="Letter Body (Delapodated)" multiline />
                <BooleanInput source="sendSecondProbateLetter" label="Send a 2nd letter to probate addresses?" />
                <TextInput source="letterBodyProbateAddress" label="Letter Body (2nd Probate)" multiline />
                <TextInput source="letterName" label="Your name (letter ending)" />
                <TextInput source="letterSignature" label="Letter signature" multiline />
            </SimpleForm>
        </Edit>
    );
}

const TextInput = styled(_TextInput)`
    width: 500px;
`;

const EditToolbar = (props: any) => (
    <Toolbar>
        <SaveButton {...props} label="Update profile" redirect="edit" />
    </Toolbar>
);

const EditAside = ({ record }: any) => (
    <div style={{ width: 300, margin: '1em', marginLeft: '2em' }}>
        <Typography variant="h6">
            <strong>
                Letter credits
            </strong>
        </Typography>
        <Typography variant="body1">
            You have <b>{record?.letterCreditsAvailable || `...`}</b> credits remaining
        </Typography>
        <Typography variant="caption">
            and used <b>{record?.letterCreditsUsed || `...`}</b> credits to date
        </Typography>
        {(record && !record.letterProductionEnabled) && (
            <Typography variant="caption">
                <br/>
                (you are in test mode)
            </Typography>
        )}
        <br/><br/>
        <Button color="secondary" variant="contained">
            Purchase more
        </Button>
        <Divider style={{marginTop: '1.5rem', marginBottom: '1.5rem'}} />
        <Typography variant="h6">
            <strong>
                Subscription
            </strong>
        </Typography>
        <Typography variant="body1">
            {record?.subscriptionExpires ?
                `Your subscription expires on ${moment(record.subscriptionExpires).format(`MMMM Do YYYY`)}` :
                `Your subscription never expires`
            }
        </Typography>
        <br/>
        <Button color="secondary" variant="contained">
            Extend
        </Button>
    </div>
);