import { fetchUtils } from "react-admin";
import getConfig from "./config";
import { getToken } from "./auth";
const jsonServerProvider = require('ra-data-json-server').default; // TS issue

const httpClient = (url: string, options = {} as Record<string, any>) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${getToken()}`);
    return fetchUtils.fetchJson(url, options);
};

export default jsonServerProvider(getConfig().apiUrl, httpClient);