import * as React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Link, Search } from "@material-ui/icons";
import styled from "styled-components";

const ResultLinks: React.FC<any> = ({ record }) => {

    const goToSite = (e: any) => {
        e.stopPropagation();
        window.open(`https://www.thegazette.co.uk/notice/${record.id}`);
    }

    const googleSearch = (e: any) => {
        e.stopPropagation();
        window.open(`https://www.google.com/search?q=${record.propertyAddress}`);
    }

    return (
        <Row>
            <Tooltip title="View on gazzette">
                <IconButton onClick={goToSite}>
                    <Link />
                </IconButton>
            </Tooltip>
            <Tooltip title="Google property">
                <IconButton onClick={googleSearch}>
                    <Search />
                </IconButton>
            </Tooltip>
        </Row>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export default ResultLinks;