import edit from "./Edit";
import { Settings as icon }  from "@material-ui/icons";

export default {
    name: "profile",
    edit,
    icon,
    options: {
        label: "Settings",
        defaultPath: "profile/me",
    }
}