import React, { cloneElement } from "react";
import { useListContext, sanitizeListRestProps, TopToolbar, CreateButton } from "react-admin";

export default (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={"/custom_lead"} />
        </TopToolbar>
    );
};
