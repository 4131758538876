import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SelectInput,
    BooleanField,
    TextInput,
    DateField,
    BooleanInput,
    useListContext,
} from "react-admin";
import Actions from "./Actions";
import ExpandedResult from "../../common/ExpandedResult";
import { Typography } from "@material-ui/core";
import MapList from "./MapList";
import { Link as _Link } from "react-router-dom";
import styled from "styled-components";

const SearchFilter = (props: any) => (
    <Filter {...props}>
        <BooleanInput
            source="mapView"
            label="Map view?"
            alwaysOn
        />
        <SelectInput
            alwaysOn
            allowEmpty={false}
            source="status"
            choices={[
                { id: 'fetched', name: 'New' },
                { id: 'ignored', name: 'Ignored' },
            ]}
        />
        <TextInput
            alwaysOn
            source="remoteId"
            label="ID"
        />
        <TextInput
            source="*propertyAddress"
            label="Address"
        />
        <TextInput
            source="*contactAddress.name"
            label="Contact"
        />
        <TextInput
            source="searchId"
            label="Search ID"
            disabled
        />
    </Filter>
);

export default (props: any) => (
    <List
        {...props}
        sort={{ field: "fetchedAt", order: "DESC" }}
        filters={<SearchFilter />}
        filterDefaultValues={{ status: "fetched" }}
        exporter={false}
        bulkActionButtons={false}
        perPage={25}
    >
        <DataGrid {...props} />
    </List>
)

const DataGrid = (props: any) => {
    const list = useListContext();
    if (list?.filterValues?.mapView) {
        if (!list?.filterValues?.searchId) {
            return (
                <TextWrapper>
                    <Typography variant="h6">
                        Data set too large, pick a {` `}
                        <Link to={"/search"}>
                            search result
                        </Link>
                    </Typography>
                </TextWrapper>
            )
        }
        const records = list.ids.map((id: any) => list.data[id]).filter((i: any) => !!i);
        return (
            <MapList records={records as any} />
        )
    }
    return (
        <Datagrid {...props} rowClick="expand" expand={<ExpandedResult />}>
            <TextField source="id" label="ID" />
            <TextField source="propertyAddress" />
            <TextField source="contact" label="Executor" />
            <BooleanField source="hasEmail" />
            <BooleanField source="hasTelephone" />
            <DateField source="published" />
            <DateField source="claimDeadline" label="Deadline" />
            <DateField source="fetchedAt" label="Fetched" />
            <Actions label="Actions" align="left" />
        </Datagrid>
    )
}

const Link = styled(_Link)`
    color: inherit;
    font-weight: bold;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
`;

const TextWrapper = styled.div`
    text-align: center;
    padding: 1rem;
`;