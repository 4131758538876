import { House as icon }  from "@material-ui/icons";
import { Edit, Create } from "./CreateEdit";
import { default as list } from "./List";

export default {
    name: "custom_lead",
    list,
    edit: Edit,
    create: Create,
    icon,
    options: {
        label: "Delapadated Leads",
    }
}