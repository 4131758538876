import * as React from "react";
import GoogleMapReact from 'google-map-react';
import { PinDrop } from "@material-ui/icons";

interface Props {
    lat: number;
    lng: number;
}

const Pin = (props: any) => <PinDrop />;

const Map = (props: Props) => (
    <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC3ODt95y5xAJK1_NLrxv72ZECu9b8-cqg" }}
          defaultCenter={props}
          defaultZoom={15}
        >
          <Pin
            lat={props.lat}
            lng={props.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
)

export default React.memo(Map);
