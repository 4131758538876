import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    EmailField,
    TextInput,
    DateField,
    BooleanField,
    SelectInput,
} from "react-admin";
import Actions from "./Actions";
import ExpandedResult from "../../common/ExpandedResult";

export const SearchFilter = (props: any) => (
    <Filter {...props}>
        <SelectInput
            alwaysOn
            allowEmpty={false}
            source="postage"
            choices={[
                { id: '$ne', name: 'Not sent' },
                { id: '$exists', name: 'Sent' },
            ]}
        />
        <TextInput
            alwaysOn
            source="remoteId"
            label="ID"
        />
        <TextInput
            source="*propertyAddress"
            label="Address"
        />
        <TextInput
            source="*contactAddress.name"
            label="Contact"
        />
    </Filter>
);

export default (props: any) => (
    <List
        {...props}
        sort={{ field: "fetchedAt", order: "DESC" }}
        filterDefaultValues={{ postage: "$ne" }}
        filters={<SearchFilter />}
        exporter={false}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="expand" expand={<ExpandedResult />}>
            <TextField source="id" label="ID" />
            <TextField source="propertyAddress" />
            <TextField source="contact" label="Executor" />
            <EmailField source="email" emptyText="none" />
            <TextField source="telephone" emptyText="none" />
            <DateField source="published" />
            <DateField source="claimDeadline" label="Deadline" />
            <DateField source="fetchedAt" label="Fetched" />
            <BooleanField
                source="letterSent"
                label="Letter sent?"
                valueLabelTrue="Letter sent"
                valueLabelFalse="Letter not sent"
            />
            <Actions label="Actions" />
        </Datagrid>
    </List>
);