import * as React from "react";
import { useState } from "react";
import {
    useDataProvider,
    useRefresh,
    useNotify,
} from "react-admin";
import { IconButton, Tooltip } from "@material-ui/core";
import { Description, Send, Delete, Edit } from "@material-ui/icons";
import styled from "styled-components";
import ResultLinks from "../../common/ResultLinks";
import PdfPreview from "./PdfPreview";
import { connect } from "react-redux";
import { deleteLeadLocal } from "../../redux/actions";
import { push } from "react-router-redux";

const Actions: React.FC<any> = ({ record, deleteLeadLocal, push, resource, allowResends = false }) => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [pressed, setPressed] = useState(false);
    const [previewPressed, setPreviewPressed] = useState(false);
    const [pdf, setPdf] = useState("");

    const sendPdf = async (e: any) => {
        e.stopPropagation();
        if (!window.confirm(`Are you sure you want to send a letter to ${record.contact}? This can't be undone`)) {
            return false;
        }
        setPressed(true);
        try {
            await dataProvider.create(`letter/${record.id}/send`, { data: {} });
            notify(`Letter sent to ${record.contact}!`);
            refresh();
        } catch (e) {
            setPressed(false);
            notify(e.toString());
            console.error(e);
        }
        return true;
    }

    const openPdf = async (e?: any, pdfType = "contact") => {
        e?.stopPropagation();
        setPreviewPressed(true);
        try {
            const { data } = await dataProvider.getOne(`letter/${pdfType}`, { id: record.id });
            setPdf(data.pdf);
        } catch (e) {
            notify(`An error occured fetching the PDF`);
        }
        setPreviewPressed(false);
    }

    const onPdfChange = record?.userGenerated ? undefined : (type: any) => {
        openPdf(undefined, type);
    }

    const onClose = (e: any) => {
        e.stopPropagation()
        setPdf("");
    }

    const editCustomLead = () => push(`/custom_lead/${record.id}`)

    const convertBackToResult = async (e: any) => {
        e.stopPropagation();
        try {
            await dataProvider.update("search_result", {
                id: record._id,
                data: { status: "fetched" },
                previousData: {
                    id: record._id,
                }
            });
            notify(`Removed as a lead, check results page`);
            deleteLeadLocal(record.id);
        } catch (e) {
            console.error(e);
        }
    }

    if (!record) {
        return null;
    }

    return (
        <>
            {pdf && (
                <PdfPreview
                    onClose={onClose}
                    pdf={pdf}
                    pdfLoading={previewPressed}
                    onPdfChange={onPdfChange}
                    title={record.propertyAddress}
                    sendPdf={sendPdf}
                />
            )}
            <Row>
                <Tooltip title="Remove lead">
                    <IconButton
                        onClick={convertBackToResult}
                        disabled={record.letterSent}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
                {!record.userGenerated && <ResultLinks record={record} />}
                <Tooltip title="Preview letter">
                    <IconButton onClick={openPdf} disabled={previewPressed}>
                        <Description />
                    </IconButton>
                </Tooltip>
                <Tooltip title={record.letterSent && !allowResends ? `Letter sent` : `${record.letterSent ? `Re-` : ``}Send letter`}>
                    <IconButton
                        disabled={(!allowResends && record.letterSent) || pressed}
                        onClick={sendPdf}
                    >
                        <Send />
                    </IconButton>
                </Tooltip>
                {record.userGenerated && !record.letterSent && (
                    <Tooltip title="Edit">
                        <IconButton onClick={editCustomLead}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
            </Row>
        </>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export default connect(undefined, {
    deleteLeadLocal,
    push,
})(Actions);
