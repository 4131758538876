export const deleteSearchResultLocal = (id: number) => ({
    type: 'RA/CRUD_DELETE_OPTIMISTIC',
    payload: { id },
    meta: {
      resource: 'search_result',
      fetch: 'DELETE',
      optimistic: true
    }
})

export const deleteLeadLocal = (id: number) => ({
  type: 'RA/CRUD_DELETE_OPTIMISTIC',
  payload: { id },
  meta: {
    resource: 'lead',
    fetch: 'DELETE',
    optimistic: true
  }
})